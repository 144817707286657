export enum RoutingEnum {
  root = "/",
  cover = "/cover",
  instructions = "/instructions",
  scan = "/scan",
  confirmation = "/confirmation",
  shootCountdown = "/shoot-countdown",
  finish = "/finish",
  secondScreen = "/second-screen",
}
