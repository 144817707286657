import React, { FC, PropsWithChildren, createContext } from "react";

import { IExperienceNameEnum } from "@/interfaces/enums/experience.enum";

import useIdleState from "./useIdleState";
import useExperience from "../experienceProvider/useExperience";

const IdleContext = createContext<any | undefined>(undefined);

const IdleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { selectedExperience } = useExperience();
  const idleState = useIdleState(selectedExperience?.name === IExperienceNameEnum.halo);

  return <IdleContext.Provider value={idleState}>{children}</IdleContext.Provider>;
};

export { IdleProvider, IdleContext };
