import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import api from "@/api";
import { QueryKeyEnum } from "@/interfaces/enums/queryKeys.enum";

import { IConfig, IApiDataState, IConfigDataDto, IUserConfig } from "../../types/apiData.type";
import { getConfigParams } from "./config.helpers";

const useConfigState = (): IApiDataState => {
  const [searchParams] = useSearchParams();

  const { data: config } = useQuery<IConfig>(
    QueryKeyEnum.config,
    () =>
      axios.get(`${window.location.origin}${api.config}`).then((res: AxiosResponse<IConfigDataDto>) => {
        const cameraIdParam: string | null = searchParams.get("cameraId");
        const uuid: string | null = searchParams.get("uuid");

        const noAuthConfig: IUserConfig = getConfigParams(cameraIdParam, uuid);

        axios.defaults.baseURL = res.data?.API_SIAPHOTOS;

        return { ...res.data, ...noAuthConfig };
      }),
    {
      refetchOnReconnect: false,
    },
  );

  return {
    config,
  };
};

export default useConfigState;
