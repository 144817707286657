import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";

import api from "@/api";
import ExperienceDataDto from "@/interfaces/rest/experience.dto";
import useConfig from "@/providers/configProvider/useConfig";
import { CameraNameEnum } from "@/interfaces/enums/cameraName.enum";
import { QueryKeyEnum } from "@/interfaces/enums/queryKeys.enum";

import { IDataForTrack, IExperienceState } from "./experience.types";

const useExperienceState = (): IExperienceState => {
  const { config } = useConfig();
  const [selectedExperience, setSelectedExperience] = useState<ExperienceDataDto | null>(null);
  const [barcode, setBarcode] = useState<string>("");

  const { data: experienceData, isError: isExperienceError } = useQuery<ExperienceDataDto>(
    QueryKeyEnum.experienceList,
    () =>
      axios
        .get(api.camerasListById(config?.cameraId || -1, String(config?.uuid)))
        .then((res: AxiosResponse<ExperienceDataDto>) => res.data),
    { enabled: !!config?.cameraId, retry: 2 },
  );

  const changeExperience = (experience: ExperienceDataDto): void => {
    setSelectedExperience(experience);
  };

  const handleBarcodeChange = (newBarcode: string): void => {
    setBarcode(newBarcode);
  };

  const collectGaData = (eventName: string, data?: IDataForTrack): void => {
    if (selectedExperience) {
      ReactGA.gtag("event", eventName, {
        time_stamp: new Date().toLocaleString(),
        device_id: CameraNameEnum[selectedExperience.name],
        ...data,
      });
    }
  };

  useEffect(() => {
    if (experienceData) {
      changeExperience(experienceData);
    }
  }, [experienceData]);

  return {
    selectedExperience,
    changeExperience,
    barcode,
    handleBarcodeChange,
    collectGaData,
    isExperienceError,
  };
};

export default useExperienceState;
