import { useContext } from "react";

import { ExperienceContext } from "./ExperienceProvider";
import { IExperienceType } from "./experience.types";

const useExperience = (): IExperienceType => {
  const context = useContext(ExperienceContext);

  if (context === undefined) {
    throw new Error("useExperience must be used within a provider");
  }

  return context;
};

export default useExperience;
