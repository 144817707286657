import { Theme } from "@mui/material/styles";
import snTheme, { CustomThemeOptions } from "@/themes/snTheme";

interface IVenueTheme {
  [key: number]: Theme;
}

const venueTheme: IVenueTheme = {
  41: snTheme,
};

const useTheme = (venueId?: number): Theme & CustomThemeOptions => {
  const defaultTheme: Theme = venueTheme["41"];
  const themeByVenue = venueId && venueTheme?.[venueId];
  return themeByVenue || defaultTheme;
};

export default useTheme;
