import React, { FC, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { ThemeProvider, Theme } from "@mui/material/styles";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";

import * as Sentry from "@sentry/react";
import Loader from "@/components/loader";

import useConfig from "./providers/configProvider/useConfig";
import AppRoutingModule from "./pages/AppRoutingModule";
import useTheme from "./hooks/useTheme";

const SENTRY_DSN = "https://ebd3629eb522d0c9446e69fa1ced1831@o4507657321381888.ingest.us.sentry.io/4507894235529216";

const ConfigChecker: FC = () => {
  const { config } = useConfig();
  const theme: Theme = useTheme(41);
  const [pubNubClient, setPubNubClient] = useState<PubNub | null>(null);

  useEffect(() => {
    if (config?.GA_ID) {
      ReactGA.initialize(config.GA_ID);
    }

    if (config?.PUBNUB_PUBLISH_KEY && config.PUBNUB_SUBSCRIBE_KEY) {
      setPubNubClient(
        new PubNub({
          publishKey: config.PUBNUB_PUBLISH_KEY,
          subscribeKey: config.PUBNUB_SUBSCRIBE_KEY,
          userId: "capture-app",
        }),
      );
    }
  }, [config]);

  useEffect(() => {
    if (config?.REACT_APP_SENTRY_ENV) {
      Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/capture\.uat\.siaphotos\.com\//,
          /^https:\/\/capture\.siaphotos\.com\//,
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        autoSessionTracking: true,
        environment: config?.REACT_APP_SENTRY_ENV,
      });
    }
  }, [config?.REACT_APP_SENTRY_ENV]);

  return config && pubNubClient ? (
    <PubNubProvider client={pubNubClient}>
      <ThemeProvider theme={theme}>
        <AppRoutingModule />
      </ThemeProvider>
    </PubNubProvider>
  ) : (
    <Loader isAbsolute isHeightFull isWidthFull />
  );
};

export default ConfigChecker;
