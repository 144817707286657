import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

import logo from "@/assets/svg/sn-logo.svg";

import Gotham from "./fonts/Gotham-Book.woff";
import GothamBold from "./fonts/Gotham-Bold.woff";
import Knockout from "./fonts/Knockout-Regular.woff";

export interface CustomThemeOptions extends ThemeOptions {
  img?: {
    logo?: string;
  };
}

const snTheme: Theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#d9b159",
    },
    secondary: {
      dark: "#222324",
      main: "#747875",
    },
    background: {
      default: "#2a2c2d",
    },
    text: {
      secondary: "#dab25a",
      primary: "#ffffff",
    },
    error: {
      main: "#ff0000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Gotham",
  },
  img: {
    logo,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Gotham'), local('Gotham-Medium'), url(${Gotham}) format('woff');
        }

        @font-face {
          font-family: 'Gotham';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: local('Gotham'), local('Gotham-Bold'), url(${GothamBold}) format('woff');
        }

        @font-face {
          font-family: 'Knockout';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Knockout'), local('Knockout-Regular'), url(${Knockout}) format('woff');
        }

        .MuiButton-root {
          font-family: 'Knockout' !important;
        }

        .MuiTypography-root {
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
        }

        p, img, svg {
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-drag: none;
        }
      `,
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "transparent",
          height: "auto",
          width: "auto",
        },
      },
    },
  },
} as CustomThemeOptions);

export default snTheme;
