import React, { FC, PropsWithChildren, createContext } from "react";

import useExperienceState from "./useExperienceState";

const ExperienceContext = createContext<any | undefined>(undefined);

const ExperienceProvider: FC<PropsWithChildren> = ({ children }) => {
  const experienceState = useExperienceState();

  return <ExperienceContext.Provider value={experienceState}>{children}</ExperienceContext.Provider>;
};

export { ExperienceProvider, ExperienceContext };
