import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate, useLocation } from "react-router";

import { RoutingEnum } from "@/interfaces/enums/routing.enum";
import getIsKioskExperience from "@/helpers/getIsKioskExperience";

import { idleSeconds, promptInBeforeIdleSeconds } from "./idle.consts";
import useExperience from "../experienceProvider/useExperience";
import { IIdleState } from "./idle.types";

const useIdleState = (noIdleTimer?: boolean): IIdleState => {
  const { selectedExperience } = useExperience();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);

  const isExperienceKioskUsed: boolean = selectedExperience ? getIsKioskExperience(selectedExperience) : false;

  const onPrompt = (): void => {
    setIsShowingModal(true);
  };

  const handleLeave = (): void => {
    setIsShowingModal(false);
    activate();

    navigate(isExperienceKioskUsed ? RoutingEnum.scan : RoutingEnum.cover);
  };

  const onIdle = (): void => {
    handleLeave();
  };

  const { activate, pause: pauseTimer } = useIdleTimer({
    onPrompt,
    onIdle,
    promptBeforeIdle: 1000 * promptInBeforeIdleSeconds,
    timeout: 1000 * idleSeconds,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
  });

  const handleResetTimer = (): void => {
    activate();
    setIsShowingModal(false);
  };

  useEffect(() => {
    if (noIdleTimer) {
      pauseTimer();
    }
  }, [noIdleTimer, location]);

  return {
    promptInBeforeIdleSeconds,
    idleSeconds,
    isPrompted: isShowingModal,
    handleLeave,
    handleResetTimer,
    pauseTimer,
  };
};

export default useIdleState;
