import React, { FC, Suspense, lazy } from "react";
import { Routes, Route } from "react-router";
import { CssBaseline } from "@mui/material";

import Loader from "@/components/loader";
import { ExperienceProvider } from "@/providers/experienceProvider/ExperienceProvider";
import { IdleProvider } from "@/providers/idleProvider/IdleProvider";
import { RoutingEnum } from "@/interfaces/enums/routing.enum";

const Root = lazy(() => import("@/pages/root"));
const Cover = lazy(() => import("@/pages/cover"));
const Instructions = lazy(() => import("@/pages/instructions"));
const Scan = lazy(() => import("@/pages/scan"));
const Confirmation = lazy(() => import("@/pages/confirmation"));
const ShootCountdown = lazy(() => import("@/pages/shootCountdown"));
const Finish = lazy(() => import("@/pages/finish"));
const SecondScreen = lazy(() => import("@/pages/secondScreen"));

const AppRoutingModule: FC = () => (
  <Suspense fallback={<Loader isAbsolute isHeightFull isWidthFull />}>
    <ExperienceProvider>
      <CssBaseline />
      <IdleProvider>
        <Routes>
          <Route path={RoutingEnum.root} element={<Root />} />
          <Route path={RoutingEnum.cover} element={<Cover />} />
          <Route path={RoutingEnum.instructions} element={<Instructions />} />
          <Route path={RoutingEnum.scan} element={<Scan />} />
          <Route path={RoutingEnum.confirmation} element={<Confirmation />} />
          <Route path={RoutingEnum.shootCountdown} element={<ShootCountdown />} />
          <Route path={RoutingEnum.finish} element={<Finish />} />
          <Route path={RoutingEnum.secondScreen} element={<SecondScreen />} />
        </Routes>
      </IdleProvider>
    </ExperienceProvider>
  </Suspense>
);

export default AppRoutingModule;
