import ExperienceDataDto from "@/interfaces/rest/experience.dto";

const oculusSearchRegExp: RegExp = /oculus/i;

const getIsKioskExperience = (selectedExperience: ExperienceDataDto): boolean => {
  const isExperienceOculus: boolean = oculusSearchRegExp.test(selectedExperience.name);

  return isExperienceOculus;
};

export default getIsKioskExperience;
