import { IUserConfig } from "@/types/apiData.type";

export const getConfigParams = (cameraIdParam: string | null, uuid: string | null): IUserConfig => {
  const parsedCameraId: number = cameraIdParam ? +cameraIdParam : 0;
  const isValidCameraId: boolean = !Number.isNaN(parsedCameraId) && parsedCameraId > 0;

  const cameraId: number = cameraIdParam && isValidCameraId ? +cameraIdParam : 0;

  const venueId: number = 41;

  return { cameraId, venueId, uuid: uuid || "" };
};
